import React, { useState } from 'react';
import * as docx from 'docx';
import { saveAs } from 'file-saver';

import Spinner from '../components/spinner';

import '../styles/eop.scss';

const initData = {
  result: null,
  error: null
};

const Eop = () => {
  const [data, setData] = useState(initData);
  const [spinner, setSpinner] = useState(false);
  const [date, setDate] = useState('');

  const handleBtnClick = () => {
    setData(initData);
    setSpinner(true);
    fetch('https://us-central1-pantaleycom.cloudfunctions.net/app/api/web-scraping', {
      // fetch('http://localhost:10001/pantaleycom/us-central1/app/api/web-scraping/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ date })
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
        setSpinner(false);
        return null;
      })
      .catch((error) => {
        setData({ ...initData, error });
      });
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const copyLink = (e) => {
    e.target.parentElement.classList.add('copiedBtn');
    e.target.select();
    e.target.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setTimeout(
      (el) => {
        el.classList.remove('copiedBtn');
      },
      600,
      e.target.parentElement
    );
  };

  const deleteEntry = (entryIndex) => {
    const result = data.result.filter((item, i) => i !== entryIndex);
    setData({ ...initData, result });
  };

  const downloadFile = () => {
    const hyperlinks = data.result.reduce(
      (acc, cur, index) => ({ ...acc, ...{ [index]: { link: cur.link, text: cur.link, type: 'EXTERNAL' } } }),
      {}
    );
    const doc = new docx.Document({ hyperlinks });

    const tableCell = (data) =>
      new docx.TableCell({
        margins: {
          top: 100,
          bottom: 100,
          left: 100,
          right: 100
        },
        children: [new docx.Paragraph(data)]
      });

    const table = new docx.Table({
      rows: [
        ...[
          new docx.TableRow({
            tableHeader: true,
            children: [
              tableCell({ children: [new docx.TextRun({ bold: true, text: 'Линк' })] }),
              tableCell({ children: [new docx.TextRun({ bold: true, text: 'Тип' })] }),
              tableCell({ children: [new docx.TextRun({ bold: true, text: 'Прогнозна стойност' })] }),
              tableCell({ children: [new docx.TextRun({ bold: true, text: 'Възложител' })] }),
              tableCell({ children: [new docx.TextRun({ bold: true, text: 'Срок за подаване на оферта' })] })
            ]
          })
        ],
        ...data.result.map(
          (item, index) =>
            new docx.TableRow({
              children: [
                tableCell({ children: [new docx.HyperlinkRef(`${index}`)] }),
                tableCell(item.type),
                tableCell(item.price),
                tableCell(item.еmployer),
                tableCell(item.deadline)
              ]
            })
        )
      ]
    });
    doc.addSection({
      children: [table]
    });

    docx.Packer.toBlob(doc)
      .then((blob) => {
        const markedDate = new Date(date).getDate();
        const currentDate = new Date();
        const fileName = `Porachki-${markedDate}-${currentDate.getDate()}.${
          currentDate.getMonth() + 1
        }.${currentDate.getFullYear()}.docx`;
        saveAs(blob, fileName);

        return null;
      })
      .catch((error) => {
        setData({ ...initData, error });
      });
  };

  return (
    <div id="eop">
      <div className="controls window">
        <label>
          От (включително):
          <input type="date" value={date} onChange={handleDateChange}></input>
        </label>
        <button className="btn" onClick={() => handleBtnClick()} disabled={!date}>
          Зареди
        </button>
      </div>
      {data && data.result && data.result.length === 1 ? (
        <div className="window">
          <button className="btn" onClick={downloadFile}>
            Свали в Word
          </button>
          <table>
            <thead>
              <tr>
                <th>Линк</th>
                <th>Тип</th>
                <th>Прогнозна стойност</th>
                <th>Възложител</th>
                <th>Срок за подаване на оферта</th>
              </tr>
            </thead>
            <tbody>
              {data.result.map((item, i) => (
                <tr key={i}>
                  <td>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      {item.link}
                    </a>
                  </td>
                  <td>{item.type}</td>
                  <td>{item.price}</td>
                  <td>{item.еmployer}</td>
                  <td>{item.deadline}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
      {data.result &&
        data.result.map((item, i) => (
          <div className="entry window" key={i}>
            <div className="entry-data-wrapper">
              <div className="entry-data">
                <small>от: {item.date}</small>
                <br />
                {item.title}
              </div>
              <div className="entey-actions">
                <label className="copy-link btn">
                  Копиране
                  <input onClick={copyLink} type="text" value={item.link} readOnly />
                </label>
                <a className="btn" href={item.link} target="_blank" rel="noreferrer">
                  Отвори
                </a>
                <button className="btn" onClick={() => deleteEntry(i)}>
                  Изтрий
                </button>
              </div>
            </div>
            {item.details ? <div className="entry-details">{item.details}</div> : null}
          </div>
        ))}
      {data && data.result && data.result.length === 0 ? <div className="window">Няма резултати</div> : null}
      {data && data.error ? <div className="window">Грешка</div> : null}
      {spinner ? <Spinner /> : ''}
    </div>
  );
};

export default Eop;
